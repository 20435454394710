import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";


// Connects to data-controller="signup-with-email"
export default class extends Controller {
  static targets = ["firstNameInput", "lastNameInput", "emailInput", "passwordInput", "accceptedTermsAndConditionsInput", "submitInput"]
  

  validate() {
    const password = this.passwordInputTarget.value;
    const email = this.emailInputTarget.value;
    const firstNameInput = this.firstNameInputTarget.value;
    const lastNameInput = this.lastNameInputTarget.value;
    const acceptedTermsAndConditions = this.accceptedTermsAndConditionsInputTarget.checked;

    if (lastNameInput == "Botterman") {
      Sentry.init({
        dsn: "https://0c6a74e11898c1df10d243f3162bb0e9@o4507702641164288.ingest.de.sentry.io/4507702643720272",
        environment: "production"
      });
      Sentry.captureMessage(`password.length ${password.length} "email.length" ${email.length} "firstNameInput.length" ${firstNameInput.length} "lastNameInput.length" ${lastNameInput.length} "acceptedTermsAndConditions" ${acceptedTermsAndConditions}`);
    }
    // FIMME: Use zod, validate email
    let valid = false;
    if (password.length > 0 && email.length > 0 && firstNameInput.length > 0 && lastNameInput.length > 0 && acceptedTermsAndConditions) {
      valid = true;
    }

    if (valid) {
      this.submitInputTarget.disabled = false;
    } else {
      this.submitInputTarget.disabled = true;
    }
  }
}
